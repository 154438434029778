// Imports
// ------
import React from 'react';
import FeaturedMedia from './FeaturedMedia';
import PostData from './PostData';
import Editor from './Editor';
import BeforeNextPost from './BeforeNextPost';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const BlogPost = ({ content, context }) => {
	const { image, author, date, featuredVideo } = content;

	return (
		<Jacket>
			<PostData author={author} date={date} />
			<FeaturedMedia content={image} video={featuredVideo} />
			<Editor data={content.content} />
			<BeforeNextPost prev={context.prevPage} next={context.nextPage} />
		</Jacket>
	);
};

export default BlogPost;
