// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		background: linear-gradient(-90deg, #303339 -100%, #080809 50%);
	`
);
