// Imports
// ------
import React from 'react';
import Icon from '@icons';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, Content, Post, IconJacket, Label } from './styles';

// Component
// ------
const BeforePostAfter = ({ prev, next }) => {
	const pageTrans = {
		preventScrollJump: true,
		exit: {
			length: 1,
		},

		entry: {
			length: 1,
			trigger: ({ node }) => {
				node.style.top = '0px';
			},
		},
	};

	return (
		<Jacket padTop>
			<Row isExpanded sidePad>
				<Column mpad>
					<Content>
						{prev && (
							<Post
								{...pageTrans}
								to={`/blog/${prev.slug}`}
								className={prev.slug ? `is-prev` : null}>
								<IconJacket>
									<Icon type='chevron-right' />
								</IconJacket>

								<Label>
									<span>Previous</span>
									<span>Post</span>
								</Label>
							</Post>
						)}

						{next && (
							<Post
								{...pageTrans}
								to={`/blog/${next.slug}`}
								className={next.slug ? `is-next` : null}>
								<IconJacket isNext>
									<Icon type='chevron-right' />
								</IconJacket>

								<Label>
									<span>Next</span>
									<span>Post</span>
								</Label>
							</Post>
						)}
					</Content>
				</Column>
			</Row>
		</Jacket>
	);
};

export default BeforePostAfter;
