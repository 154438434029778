// Imports
// ------
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket, Content, Video, Image } from './styles';

// Component
// ------
const FeaturedMedia = ({ content, video }) => {

	const [videoReady, setVideoReady] = useState(false);

	const handleVideo = () => {
		setVideoReady(true);
	};

	return (
		<Jacket padTop>
			<Content onClick={handleVideo} videoReady={videoReady}>
				{!video?.url && content?.gatsbyImageData &&
					<GatsbyImage
						image={content.gatsbyImageData}
						alt={content.alt ? content.alt : content.smartTags}
					/>
				}
				{video?.url &&
					<Video>
						<ReactPlayer
							url={video.url}
							playing={videoReady}
							controls
							width={`100%`}
							height={`100%`}
						/>
					</Video>
				}
			</Content>
		</Jacket>
	)
};

export default FeaturedMedia;
