// Imports
// ------
import React from 'react';
import ReactPlayer from 'react-player/lazy';

// Styles
// ------
import { Jacket } from './styles.js';

// Component
// ------
const ExternalVideo = ({ video }) => {

    return (
        <Jacket>
            <ReactPlayer
                url={video.url}
                controls
                width="100%"
                height="0"
                style={{ paddingTop: '60%' }}
            />
        </Jacket>
    );
};

export default ExternalVideo;
