// Imports
// ------
import React from 'react';
import { StructuredText } from "react-datocms";
import { Row, Column } from '@waffl';
import MasonryGrid from '../../ProjectBuilder/MasonryGrid';
import SingleImage from '../../ProjectBuilder/SingleImage';
import ExternalVideo from '../../ProjectBuilder/ExternalVideo';


// Styles
// ------
import { Jacket, Content } from './styles';

// Component
// ------
const Editor = ({ data }) => (
	<Jacket>
		<Content>
			<StructuredText
				data={data}
				renderBlock={({ record }) => {
					switch (record.__typename) {
						case "DatoCmsMasonry":
							return <MasonryGrid blog style={{ margin: '5rem auto' }} images={record.images} isLast={false} />;
						case "DatoCmsSingleImage":
							return <SingleImage style={{ margin: '5rem auto' }} image={record.image} />;
						case "DatoCmsExternalVideo":
							return <ExternalVideo video={record.video} />;
						default: return null;
					}
				}}
			/>
		</Content>
	</Jacket>
);

export default Editor;
