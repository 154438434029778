// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		max-width: 112rem;
		padding: 0;
		margin: 0 auto;
		width: 90%;
	`
);

export const Content = styled.div(
	(props) => css`
		position: relative;

		${breakup.large`
			padding: 0 1.2rem;
			height: auto;
		`}

		.gatsby-image-wrapper {
			position: relative;
			top: auto;
			bottom: auto;
			right: auto;
			left: auto;
		}
	`
);

export const Video = styled.div(
	(props) => css`
		z-index: 1;
		width: 100%;
		
		${breakup.large`
			padding-top: 60%;
		`}

		> div {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	`
);
