// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, Em } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		margin: 0 0 6rem 0;
		padding: 0;
	`
);

const sharedEmStyles = css`
	text-transform: none;
	margin: 0 0 0 1.2rem;
	font-weight: 300;

	${breakup.large`
		margin: 0 0 0 2.4rem;
	`}
`;

export const Content = styled.div(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;
	`
);

export const Author = styled.div(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 0 2.4rem 0 0;

		${breakup.large`
			margin: 0 6rem 0 0;
		`}

		.gatsby-image-wrapper {
			border-radius: 100%;
			width: 3.6rem;
			height: 3.6rem;

			${breakup.large`
				width: 6rem;
				height: 6rem;
			`}
		}
	`
);

export const AuthorName = styled(Em)(
	(props) => css`
		${sharedEmStyles}
	`
);

export const Date = styled.div(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
	`
);

export const ActualDate = styled(Em)(
	(props) => css`
		${sharedEmStyles}
	`
);
