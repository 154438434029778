// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		margin: 1rem 0 3rem 0;
		position: relative;

		iframe {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	`
);
