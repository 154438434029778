// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/SeoPost';
import PageTransition from '@parts/PageTransition';
import Hero from '@parts/Hero/Blog';
import BlogPost from '@parts/BlogPost';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function Post({ transitionStatus, entry, exit, data, pageContext }) {
	const ts = transitionStatus;
	const bp = useBreakpoint();

	useEffect(() => {
		const hideNotification = action(() => {
			headerState.notifyActive = false;
			headerState.notifySize = 0;
		});

		hideNotification();

		const timer = setTimeout(
			() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			bp.large ? 500 : 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<>
			<Seo
				data={data.page.seo}
				postTitle={data.page.title}
				backupData={data.globalSeo.seo}
				thumbnail={data.page?.image?.url}
			/>

			<PageTransition status={ts} entry={entry} exit={exit}>
				<Hero title={data.page.title} />
				<BlogPost content={data.page} context={pageContext} />
				<Newsletter />
				<Footer />
			</PageTransition>
		</>
	);
}

export default Post;

// GraphQL
// ------
export const query = graphql`
	query BlogPostQuery($slug: String!) {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
		page: datoCmsBlogPost(slug: { eq: $slug }) {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
			title
			category {
				type: category
			}
			image: featuredImage {
				url
				gatsbyImageData(
					width: 1440
					placeholder: BLURRED
					forceBlurhash: false
					layout: FULL_WIDTH
				)
				alt
				smartTags
			}
			content {
				value
				blocks {
					__typename
					... on DatoCmsSingleImage {
						id: originalId
						image {
							gatsbyImageData(
								width: 1440
								placeholder: BLURRED
								forceBlurhash: false
								layout: FULL_WIDTH
								backgroundColor: ""
							)
							alt
							smartTags
						}
					}
	
					... on DatoCmsMasonry {
						id: originalId
						images {
							gatsbyImageData(
								width: 720
								placeholder: BLURRED
								forceBlurhash: false
								layout: FULL_WIDTH
								backgroundColor: ""
							)
							alt
							smartTags
						}
					}
					... on DatoCmsExternalVideo {
						id: originalId
						video {
							url
						}
					}
				}
			  }
			featuredVideo {
				url
			}
			author {
				authorName
				authorPhoto {
					gatsbyImageData(
						width: 60
						height: 60
						placeholder: DOMINANT_COLOR
						forceBlurhash: false
						layout: CONSTRAINED
					)
					alt
					smartTags
				}
			}
			date: meta {
				# created: publishedAt(formatString: "DD MMM, YYYY")
				# created: firstPublishedAt(formatString: "DD MMM, YYYY")
				created: createdAt(formatString: "DD/MM/YYYY")
				# created: updatedAt(formatString: "DD MMM, YYYY")
			}
		}
	}
`;
