// Imports
// ------
import React from 'react';
import Icon from '@icons';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import {
	Jacket,
	Content,
	Author,
	AuthorName,
	Date,
	ActualDate,
} from './styles';

// Component
// ------
const PostData = ({ author, date }) => {
	const { authorName, authorPhoto } = author;

	return (
		<Jacket padTop>
			<Row isExpanded sidePad>
				<Column small={12} large={8} pushOnLarge={2} mpad>
					<Content>
						<Author>
							<GatsbyImage
								image={authorPhoto.gatsbyImageData}
								alt={authorPhoto.alt ? authorPhoto.alt : authorPhoto.smartTags}
							/>
							<AuthorName>By {authorName}</AuthorName>
						</Author>

						<Date>
							<Icon type='calendar' />
							<ActualDate>{date.created}</ActualDate>
						</Date>
					</Content>
				</Column>
			</Row>
		</Jacket>
	);
};

export default PostData;
