// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, WYSIWYG } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		padding-top: 6rem;
		max-width: 87rem;
		width: 90%;
		margin: 0 auto 12em auto;

		p {
			margin-bottom: 2.4rem;
			font-size: 1.8rem;
			line-height: 1.55em;

			@media only screen and (max-width: 60rem) {
				font-size: 1.6rem;
			}
		}

		h2 {
			font-size: 3.5rem;
			margin: 0 0 0.75em 0;
			line-height: 1.2em;
		}

		p + h2 {
			margin-top: 1.35em;
		}

		h3 {
			font-size: 2.5rem;
			margin: 1.75em 0 0.75em 0;
			line-height: 1.2em;
		}
	`
);

export const Content = styled(WYSIWYG)(
	(props) => css`
		//-
	`
);
